import React, { useEffect,Suspense } from 'react';
import Header from '../components/Header';
import phata from '../assets/phata.gif';
import Whatsapp from '../components/FloatingWhatsapp.js';
import Footer from '../components/Footer';
import ReactPlayer from 'react-player';
import ReactLoading from '../assets/React-Loading.gif';
import '../styles/Developmentupdates.css';
import {Helmet} from 'react-helmet-async';

const DevelopmentUpdates = () => {


  const developmentUpdates = [
    {
      id: 8,
      imageSrc: "https://ik.imagekit.io/urafoew8x/Posts/NpfD1Dev4.jpg?updatedAt=1737273163359",
      heading: 'Latest Work Updates...',
      details: 'یہ نیا ترقیاتی منصوبہ آپ کے لئے بہترین سرمایہ کاری کا موقع فراہم کرتا ہے۔'
    },
    {
      id: 7,
      imageSrc: "https://ik.imagekit.io/urafoew8x/Posts/NpfD1Dev3.jpg?updatedAt=1737273163476",
      heading: 'Latest Work Updates...',
      details: 'ڈی ون کیپٹل پارک سٹی میں روز بروز ترقیاتی کام تیز ہورہے ہیں۔'
    },
    {
      id: 6,
      imageSrc: "https://ik.imagekit.io/urafoew8x/Posts/NpfD1Dev2.jpg?updatedAt=1737273163677",
      heading: 'Latest Work Updates...',
      details: 'یہ منصوبہ ہمارے عظیم خواب کی حقیقت بننے کی طرف ایک اور قدم ہے۔'
    },
    {
      id: 5,
      imageSrc: "https://ik.imagekit.io/urafoew8x/Posts/NpfD1Dev1.jpg?updatedAt=1737273163594",
      heading: 'Latest Work Updates...',
      details: 'ڈی ون کیپٹل پارک سٹی کے ترقیاتی کام تیز رفتاری سے جاری ہیں، اور ہر دن نیا سنگ میل عبور کیا جا رہا ہے۔'
    },
    {
      id: 1,
      imageSrc: "https://ik.imagekit.io/urafoew8x/Posts/Capital%20Park%20City%20(9).png?updatedAt=1724092016173",
      heading: 'Work in Progress...',
      details: 'ڈی ون کیپٹل پارک سٹی تعمیر و ترقی کی ایک بہترین مثال ہے، جہاں آپ اپنے نئے مستقبل کی شروعات کر سکتے ہیں۔'
    },
    {
      id: 2,
      imageSrc: "https://ik.imagekit.io/urafoew8x/Posts/Capital%20Park%20City%20(12).png?updatedAt=1724092016098",
      heading: 'Work in Progress...',
      details: 'ڈی ون کیپٹل پارک سٹی: تیز تعمیر و ترقی کا سفر جاری ہے'
    },
    {
      id: 3,
      imageSrc: "https://ik.imagekit.io/urafoew8x/Posts/Capital%20Park%20City%20(11).png?updatedAt=1724092016022",
      heading: 'Work in Progress...',
      details: 'ڈی ون کیپٹل پارک سٹی میں انویسٹ کرنے کا مطلب ہے کہ آپ بہترین انویسٹمنٹ کر رہے ہیں، جس سے صرف آپ ہی نہیں، آپ کے پیاروں کا مستقبل بھی محفوظ ہوتا ہے۔'
    },
 
    {
      id: 4,
      imageSrc: "https://ik.imagekit.io/urafoew8x/Posts/Capital%20Park%20City%20(10).png?updatedAt=1724092016019",
      heading: 'Work in Progress...',
      details: 'آپ کے سنہرے کل کی تعمیر کے لئے ہماری ٹیم 24 گھنٹے سائٹ پر ترقیاتی کام جاری رکھے ہوئے ہے'
    }
  ];

  const developmentVideos = [
    {
      id: 101,
      videoSrc: 'https://www.facebook.com/NPFD1capitalparkcityisb/videos/rapid-development-progress-at-NPFD1-capital-park-city-islamabadfor-more-information/342231105359940/',
      heading: 'Land Leveling',
      details: 'NPF-D1 Capital Park City is working on the leveling of land'
    },
    {
      id: 102,
      videoSrc: 'https://www.facebook.com/watch/?v=342564135261824',
      heading: 'Rapid Development',
      details: 'NPF-D1 Capital Park City: Rapid Development & Progress'
    },
    {
      id: 103,
      videoSrc: 'https://www.facebook.com/watch/?v=693174199314199',
      heading: 'Ring Road',
      details: 'NPF-D1 Capital Park City: Connecting with Ring Road'
    },
   
  ];
  
  useEffect(() => {
    document.title = 'NPF-D1 Capital Park City | Development Updates';
  },[]);

  useEffect(() => {
    const text = document.querySelector('.development-text');
    const words = text.innerText.split(' ');

    // Reset the inner text to empty
    text.innerText = '';

    // Iterate over each word and append it with a delay
    words.forEach((word, index) => {
      const span = document.createElement('span');
      span.innerText = word + ' ';
      span.style.opacity = 0;
      span.style.animation = `fadeIn 1s ${index * 0.2}s forwards`;
      text.appendChild(span);
    });

    // Define fadeIn animation
    const style = document.createElement('style');
    style.innerHTML = `
      @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    `;
    document.head.appendChild(style);

    // Cleanup function to remove animation styles when component unmounts
    return () => {
      document.head.removeChild(style);
    };
  }, []); // Run this effect only once after the component mounts

  return (
    <div>
      <Helmet>
        <title>NPFD1 Capital Park City | Development Updates</title>
        <meta name="description" content="NPF-D1 Capital Park City Latest Development Updates."/>
        <link rel='canonical' href='https://NPFD1.com/deveolpmentupdates'/>
      </Helmet>
    <Suspense fallback={<div style={{ backgroundColor:"#000", width:"100%", height:"100vh" }}><img style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '20px', borderRadius: '8px' }} src={ReactLoading} alt="Loading..." /></div>}>
      <Header />
      <div className="phata">
        <img src={phata} alt="Phata" />
      </div>

      <div>
        <h1 className='h1stake'>DEVELOPMENT UPDATES</h1>
      </div>

      <div className="headline-container">
        <div className="right-to-left-headline">
        <span className="development-red-text">LATEST UPDATES: </span>
          <span className="development-text">
             NPF-D1 Capital Park City is working on land-leveling.....
          </span>
        </div>
      </div>
      <br/>



<div className='development-new-container'>
      <div className="development-updates-container">
        {/* Map over developmentUpdates to render each video */}
        {developmentVideos.map(updatevideo => (
          <div key={updatevideo.id} className="development-update-card">
            <ReactPlayer
        url={updatevideo.videoSrc}
        controls={true}
        width="100%"
        height="auto"
      />
            <h2 className="development-card-heading">{updatevideo.heading}</h2>
            <p className="development-card-video-details">{updatevideo.details}</p>
          </div>
        ))}
      </div>







      <div className="development-updates-container">
      {developmentUpdates.map(update => (
        <div key={update.id} className="development-update-card">
          <img src={update.imageSrc} alt={update.heading} className="development-card-image" loading='lazy'/>
          <h2 className="development-card-heading">{update.heading}</h2>
          <p className="development-card-details">{update.details}</p>
        </div>
      ))}
    </div>



    </div>



<Whatsapp/>

      <Footer />
      </Suspense>
    </div>
  );
};

export default DevelopmentUpdates;
